:root {
  --background-color: rgb(3, 22, 50);

  --peer-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2),
    0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.12);
  --peer-border: 1px solid rgba(255, 255, 255, 0.15);
  --peer-empty-avatar: url("./images/icons/avatar_placeholder.svg");
  --peer-bg-color: rgba(255, 255, 255, 0.33);
  --peer-video-bg-color: rgba(0, 0, 0, 0.75);

  --clock-light: url("./images/clock-light.svg");
  --icon-divider: url("./images/icon-divider.svg");
  --coffee-togo-light: url("./images/coffee-togo-light.svg");

  --active-speaker-border-color: rgb(184, 9, 46);
  --selected-peer-border-color: rgba(55, 126, 255, 1);
}

html {
  height: 100%;
  width: 100%;
  font-family: "Roboto";
  font-weight: 300;
  margin: 0;
  box-sizing: border-box;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

body {
  height: 100%;
  width: 100%;
  font-size: 16px;
  margin: 0;
  overflow: hidden;
}

#conference-element {
  height: 100%;
  width: 100%;
}
